<template>
  <div>
    <h1>สรุปภาพรวม</h1>
    <h1>Powerball</h1>
    <v-row align="center">
      <v-col cols="1"><span>งวดที่ออก:</span></v-col>
      <v-col cols="4">
        <v-text-field
          class="pt-6"
          v-model="POWERBALL_DRAW_DATE"
          label="งวดที่ออก:"
          hint="ตัวอย่าง 2022-01-31"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-btn
          @click="searchDashboard(POWERBALL_DRAW_DATE, 'POWERBALL_DRAW_DATE')"
          >ค้นหา</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" sm="12">
        <v-card
          color="primary"
          class="pa-2"
          style="color: white; font-weight: 800"
        >
          <v-card-title> จำนวนคนซื้องวดปัจจุบัน</v-card-title>
          <v-card-text class="text-end" style="color: white">
            <h1 class="text-end" style="color: white; font-weight: 800">
              {{ dashboardData.countUserPowerball.countUser }}
            </h1>
            คน
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <v-card
          color="success"
          class="pa-2"
          style="color: white; font-weight: 800"
        >
          <v-card-title> ยอดขายรวมงวดปัจจุบัน</v-card-title>
          <v-card-text class="text-end" style="color: white">
            <h1 class="text-end" style="color: white; font-weight: 800">
              {{ dashboardData.sumByDrawDatePowerball.totalPrice }}
            </h1>
            บาท
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <v-card
          color="warning"
          class="pa-2"
          style="color: white; font-weight: 800"
        >
          <v-card-title> ยอดขายรวมวันนี้</v-card-title>
          <v-card-text class="text-end" style="color: white">
            <h1 class="text-end" style="color: white; font-weight: 800">
              {{ dashboardData.sumByDatePowerball.totalPrice }}
            </h1>
            บาท
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <br />
    <h1>Powerball Aus</h1>
    <v-row align="center">
      <v-col cols="1"><span>งวดที่ออก:</span></v-col>
      <v-col cols="4">
        <v-text-field
          class="pt-6"
          v-model="POWERBALL_AUS_DRAW_DATE"
          label="งวดที่ออก:"
          hint="ตัวอย่าง 2022-01-31"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-btn
          @click="
            searchDashboard(POWERBALL_AUS_DRAW_DATE, 'POWERBALL_AUS_DRAW_DATE')
          "
          >ค้นหา</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" sm="12">
        <v-card
          color="primary"
          class="pa-2"
          style="color: white; font-weight: 800"
        >
          <v-card-title> จำนวนคนซื้องวดปัจจุบัน</v-card-title>
          <v-card-text class="text-end" style="color: white">
            <h1 class="text-end" style="color: white; font-weight: 800">
              {{ dashboardData.countUserPowerballAU.countUser }}
            </h1>
            คน
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <v-card
          color="success"
          class="pa-2"
          style="color: white; font-weight: 800"
        >
          <v-card-title> ยอดขายรวมงวดปัจจุบัน</v-card-title>
          <v-card-text class="text-end" style="color: white">
            <h1 class="text-end" style="color: white; font-weight: 800">
              {{ dashboardData.sumByDrawDatePowerballAU.totalPrice }}
            </h1>
            บาท
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <v-card
          color="warning"
          class="pa-2"
          style="color: white; font-weight: 800"
        >
          <v-card-title> ยอดขายรวมวันนี้</v-card-title>
          <v-card-text class="text-end" style="color: white">
            <h1 class="text-end" style="color: white; font-weight: 800">
              {{ dashboardData.sumByDatePowerballAU.totalPrice }}
            </h1>
            บาท
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <br />
    <h1>MegaMillions US</h1>
    <v-row align="center">
      <v-col cols="1"><span>งวดที่ออก:</span></v-col>
      <v-col cols="4">
        <v-text-field
          class="pt-6"
          v-model="POWERBALL_MEGA_DRAW_DATE"
          label="งวดที่ออก:"
          hint="ตัวอย่าง 2022-01-31"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-btn
          @click="
            searchDashboard(
              POWERBALL_MEGA_DRAW_DATE,
              'POWERBALL_MEGA_DRAW_DATE'
            )
          "
          >ค้นหา</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" sm="12">
        <v-card
          color="primary"
          class="pa-2"
          style="color: white; font-weight: 800"
        >
          <v-card-title> จำนวนคนซื้องวดปัจจุบัน</v-card-title>
          <v-card-text class="text-end" style="color: white">
            <h1 class="text-end" style="color: white; font-weight: 800">
              {{ dashboardData.countUserPowerballMEGA.countUser }}
            </h1>
            คน
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <v-card
          color="success"
          class="pa-2"
          style="color: white; font-weight: 800"
        >
          <v-card-title> ยอดขายรวมงวดปัจจุบัน</v-card-title>
          <v-card-text class="text-end" style="color: white">
            <h1 class="text-end" style="color: white; font-weight: 800">
              {{ dashboardData.sumByDrawDatePowerballMEGA.totalPrice }}
            </h1>
            บาท
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <v-card
          color="warning"
          class="pa-2"
          style="color: white; font-weight: 800"
        >
          <v-card-title> ยอดขายรวมวันนี้</v-card-title>
          <v-card-text class="text-end" style="color: white">
            <h1 class="text-end" style="color: white; font-weight: 800">
              {{ dashboardData.sumByDatePowerballMEGA.totalPrice }}
            </h1>
            บาท
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <br />
    <h1>EuroJackpot EU</h1>
    <v-row align="center">
      <v-col cols="1"><span>งวดที่ออก:</span></v-col>
      <v-col cols="4">
        <v-text-field
          class="pt-6"
          v-model="POWERBALL_EURO_DRAW_DATE"
          label="งวดที่ออก:"
          hint="ตัวอย่าง 2022-01-31"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-btn
          @click="
            searchDashboard(
              POWERBALL_EURO_DRAW_DATE,
              'POWERBALL_EURO_DRAW_DATE'
            )
          "
          >ค้นหา</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" sm="12">
        <v-card
          color="primary"
          class="pa-2"
          style="color: white; font-weight: 800"
        >
          <v-card-title> จำนวนคนซื้องวดปัจจุบัน</v-card-title>
          <v-card-text class="text-end" style="color: white">
            <h1 class="text-end" style="color: white; font-weight: 800">
              {{ dashboardData.countUserPowerballEUJ.countUser }}
            </h1>
            คน
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <v-card
          color="success"
          class="pa-2"
          style="color: white; font-weight: 800"
        >
          <v-card-title> ยอดขายรวมงวดปัจจุบัน</v-card-title>
          <v-card-text class="text-end" style="color: white">
            <h1 class="text-end" style="color: white; font-weight: 800">
              {{ dashboardData.sumByDrawDatePowerballEUJ.totalPrice }}
            </h1>
            บาท
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <v-card
          color="warning"
          class="pa-2"
          style="color: white; font-weight: 800"
        >
          <v-card-title> ยอดขายรวมวันนี้</v-card-title>
          <v-card-text class="text-end" style="color: white">
            <h1 class="text-end" style="color: white; font-weight: 800">
              {{ dashboardData.sumByDatePowerballEUJ.totalPrice }}
            </h1>
            บาท
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <br />
    <h1>Mini Loto JP</h1>
    <v-row align="center">
      <v-col cols="1"><span>งวดที่ออก:</span></v-col>
      <v-col cols="4">
        <v-text-field
          class="pt-6"
          v-model="POWERBALL_JAPAN_DRAW_DATE"
          label="งวดที่ออก:"
          hint="ตัวอย่าง 2022-01-31"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-btn
          @click="
            searchDashboard(
              POWERBALL_JAPAN_DRAW_DATE,
              'POWERBALL_JAPAN_DRAW_DATE'
            )
          "
          >ค้นหา</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" sm="12">
        <v-card
          color="primary"
          class="pa-2"
          style="color: white; font-weight: 800"
        >
          <v-card-title> จำนวนคนซื้องวดปัจจุบัน</v-card-title>
          <v-card-text class="text-end" style="color: white">
            <h1 class="text-end" style="color: white; font-weight: 800">
              {{ dashboardData.countUserPowerballMINI.countUser }}
            </h1>
            คน
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <v-card
          color="success"
          class="pa-2"
          style="color: white; font-weight: 800"
        >
          <v-card-title> ยอดขายรวมงวดปัจจุบัน</v-card-title>
          <v-card-text class="text-end" style="color: white">
            <h1 class="text-end" style="color: white; font-weight: 800">
              {{ dashboardData.sumByDrawDatePowerballMINI.totalPrice }}
            </h1>
            บาท
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <v-card
          color="warning"
          class="pa-2"
          style="color: white; font-weight: 800"
        >
          <v-card-title> ยอดขายรวมวันนี้</v-card-title>
          <v-card-text class="text-end" style="color: white">
            <h1 class="text-end" style="color: white; font-weight: 800">
              {{ dashboardData.sumByDatePowerballMINI.totalPrice || 0 }}
            </h1>
            บาท
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <br />
    <h1>หวยลาว</h1>
    <v-row align="center">
      <v-col cols="1"><span>งวดที่ออก:</span></v-col>
      <v-col cols="4">
        <v-text-field
          class="pt-6"
          v-model="LOTTERY_DATE"
          label="งวดที่ออก:"
          hint="ตัวอย่าง 2022-01-31"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-btn @click="searchDashboard(LOTTERY_DATE, 'LOTTERY_DATE')"
          >ค้นหา</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" sm="12">
        <v-card
          color="primary"
          class="pa-2"
          style="color: white; font-weight: 800"
        >
          <v-card-title> จำนวนคนซื้องวดปัจจุบัน</v-card-title>
          <v-card-text class="text-end" style="color: white">
            <h1 class="text-end" style="color: white; font-weight: 800">
              {{ dashboardData.countUserLao.countUser }}
            </h1>
            คน
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <v-card
          color="success"
          class="pa-2"
          style="color: white; font-weight: 800"
        >
          <v-card-title> ยอดขายรวมงวดปัจจุบัน</v-card-title>
          <v-card-text class="text-end" style="color: white">
            <h1 class="text-end" style="color: white; font-weight: 800">
              {{ dashboardData.sumByDrawDateLao.totalPrice }}
            </h1>
            บาท
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <v-card
          color="warning"
          class="pa-2"
          style="color: white; font-weight: 800"
        >
          <v-card-title> ยอดขายรวมวันนี้</v-card-title>
          <v-card-text class="text-end" style="color: white">
            <h1 class="text-end" style="color: white; font-weight: 800">
              {{ dashboardData.sumByDateLao.totalPrice }}
            </h1>
            บาท
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- <h2>เลขท้ายใน Stock</h2> -->
    <!-- <v-row>
      <v-col cols="12" md="6" sm="12">
        <v-card class="pa-2">
          <h3 style="padding: 10px"><b>เลขท้าย 2 ตัว</b></h3>
          <v-data-table
            :headers="headerstwoDigits"
            :items="twoDigits"
            :sort-by="['calories', 'fat']"
            :sort-desc="[false, true]"
            :items-per-page="15"
            multi-sort
            class="elevation-1"
          ></v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-card class="pa-2">
          <h3 style="padding: 10px"><b>เลขท้าย 3 ตัว</b></h3>
          <v-data-table
            :headers="headersthreeDigits"
            :items="threeDigits"
            :sort-by="['calories', 'fat']"
            :sort-desc="[false, true]"
            :items-per-page="15"
            multi-sort
            class="elevation-1"
          ></v-data-table>
        </v-card>
      </v-col>
    </v-row> -->
  </div>
</template>
<script>
import { Encode, Decode } from "@/services";
export default {
  data() {
    return {
      headerstwoDigits: [
        {
          text: "เลขท้าย 2 ตัว",
          align: "start",
          sortable: false,
          value: "last2Number",
        },

        { text: "จำนวนคงเหลือ", value: "countAmt" },
      ],
      twoDigits: [],
      headersthreeDigits: [
        {
          text: "เลขท้าย 3 ตัว",
          align: "start",
          sortable: false,
          value: "last3Number",
        },

        { text: "จำนวนคงเหลือ", value: "countAmt" },
      ],
      threeDigits: [],
      userPuchaseAmt: 0,
      countPeriodSell: 0,
      countthisPeriodSell: 0,
      dashboardData: null,

      POWERBALL_DRAW_DATE: "",
      POWERBALL_AUS_DRAW_DATE: "",
      POWERBALL_MEGA_DRAW_DATE: "",
      POWERBALL_EURO_DRAW_DATE: "",
      POWERBALL_JAPAN_DRAW_DATE: "",
      LOTTERY_DATE: "",
    };
  },
  created() {
    this.getDashboard();
    this.gettwoDigits();
    this.getthreeDigits();
    this.getUserPurchase();
    this.gethisPeriodSell();
    this.callCountPeriodSell();
    this.getData();
  },
  methods: {
    async searchDashboard(val, name) {
      const auth = {
        headers: { Authorization: `Bearer ` },
      };
      const data = {
        value1: val,
        configName: name,
      };
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/configs/`,
        data,
        auth
      );
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `ดึงข้อมูลสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });

        await this.getData();
      } else {
        this.$swal.fire({
          icon: "error",
          text: `ดึงข้อมูลไม่สำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async getData() {
      this.user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const POWERBALL_DRAW_DATE = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/POWERBALL_DRAW_DATE`,
        auth
      );
      const POWERBALL_AUS_DRAW_DATE = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/POWERBALL_AUS_DRAW_DATE`,
        auth
      );
      const POWERBALL_MEGA_DRAW_DATE = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/POWERBALL_MEGA_DRAW_DATE`,
        auth
      );
      const POWERBALL_EURO_DRAW_DATE = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/POWERBALL_EURO_DRAW_DATE`,
        auth
      );
      const POWERBALL_JAPAN_DRAW_DATE = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/POWERBALL_JAPAN_DRAW_DATE`,
        auth
      );
      const LOTTERY_DATE = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/LOTTERY_DATE`,
        auth
      );
      this.POWERBALL_DRAW_DATE = POWERBALL_DRAW_DATE.data.data.value1;
      this.POWERBALL_AUS_DRAW_DATE = POWERBALL_AUS_DRAW_DATE.data.data.value1;
      this.POWERBALL_MEGA_DRAW_DATE = POWERBALL_MEGA_DRAW_DATE.data.data.value1;
      this.POWERBALL_EURO_DRAW_DATE = POWERBALL_EURO_DRAW_DATE.data.data.value1;
      this.POWERBALL_JAPAN_DRAW_DATE = POWERBALL_JAPAN_DRAW_DATE.data.data.value1;
      this.LOTTERY_DATE = LOTTERY_DATE.data.data.value1;
    },
    async getDashboard() {
      this.user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction/dashboard`,
        auth
      );
      console.log("dashboardData", response.data.data);
      this.dashboardData = response.data.data;
    },
    async gettwoDigits() {
      this.user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/lottery/getLast2Amt`,
        auth
      );
      console.log("getAllLotto", response.data.data);
      this.twoDigits = response.data.data;
      for (let i in this.twoDigits) {
        this.twoDigits[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },
    async getthreeDigits() {
      this.user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/lottery/getLast3Amt`,
        auth
      );
      console.log("getAllLotto", response.data.data);
      this.threeDigits = response.data.data;
      for (let i in this.threeDigits) {
        this.threeDigits[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },
    async getUserPurchase() {
      this.user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction/userPuchaseAmt`,
        auth
      );
      console.log("userPuchaseAmt", response.data.data[0]);
      this.userPuchaseAmt = response.data.data[0].countUser;
    },
    async gethisPeriodSell() {
      this.user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction/countPeriodSell?lotteryDate='2021-08-01'`,
        auth
      );
      console.log("countThisPeriodSell", response.data.data[0]);
      this.countThisPeriodSell = response.data.data[0].countAmt;
    },
    async callCountPeriodSell() {
      this.user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction/countPeriodSell`,
        auth
      );
      console.log("countPeriodSell", response.data.data[0]);
      this.countPeriodSell = response.data.data[0].countAmt;
    },
  },
};
</script>
